import './index.less'
import { Divider, Form, Button, Input } from 'ant-design-vue'
import { updateUserInfo } from '@/api/currentuser'

export default {
  name: 'FormResetPassword',
  props: Object.assign({}),
  data() {
    return {
      loading: false,
      form: this.$form.createForm(this, { name: 'FormResetPassword' })
    }
  },
  methods: {
    handleCancel() {
      // 取消提交事件发出
      this.$emit('cancel')
      // 重置form内容
      this.form.resetFields()
    },
    handleSubmit(e) {
      // 表单提交事件发出
      e.preventDefault()
      this.form.validateFields(async(err, values) => {
        if (!err) {
          this.loading = true
          try {
            const res = await updateUserInfo(values)
            if (!res.code) {
              this.$message.success('修改密码成功!')
              this.$emit('closeModal')
              this.form.resetFields()
            }
          } catch (error) {
            console.log(error)
          } finally {
            this.loading = false
          }
        }
      })
    }
  },

  render() {
    const { getFieldDecorator } = this.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 22 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 16 }
      }
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 4
        }
      }
    }
    return (
      <div class='FormResetPassword'>
        <h3>
          修改密码</h3>
        <Divider style={{ margin: '20px 0 20px' }} />
        <Form form={this.form}
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={this.handleSubmit} layout='horizontal'>
          <Form.Item label="原密码" >
            {getFieldDecorator('oldPassword', {
              initialValue: '',
              trigger: 'change',
              validateTrigger: 'blur',
              ...this.$validator.complexPwdFun('原密码')
            })(<Input.Password placeholder='请输入原始密码' maxLength={20} />)}
          </Form.Item>
          <Form.Item label="新密码" >
            {getFieldDecorator('password', {
              initialValue: '',
              trigger: 'change',
              validateTrigger: 'blur',
              ...this.$validator.complexPwdFun('新密码')
            })(<Input.Password placeholder='请输入8-16位新密码' maxLength={20} />)}
          </Form.Item>
          <Form.Item label="确认新密码" >
            {getFieldDecorator('newPasswordAffirm', {
              initialValue: '',
              trigger: 'change',
              validateTrigger: 'blur',
              ...this.$validator.requiredAndIsSame(this.form.getFieldValue('password'), '请再次输入新密码', '两次密码输入不一致')
            })(<Input.Password placeholder='请再次输入新密码' maxLength={20} />)}
          </Form.Item>
          <Form.Item
            labelCol={tailFormItemLayout.labelCol}
            wrapperCol={tailFormItemLayout.wrapperCol} >
            <div class='btnBroup'>
              <Button type="default" onClick={this.handleCancel}>
                取消
              </Button>
              <Button loading={this.loading} type="primary" htmlType="submit" class='btnItem'>
                确定
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
    )
  }
}
