<!--
 * @Description: 个人中心-基本资料
-->
<template>
  <div class="user_info_wrapper FormUserBaseInfo">
    <h2>基本设置</h2>
    <a-form-model ref="form" :model="form" class="user_info_form" :rules="rules">
      <div class="formBody">
        <div class="left">
          <a-form-model-item label="登录账号" required>
            <a-input v-model="form.username" disabled />
          </a-form-model-item>

          <a-form-model-item label="用户名称" prop="realname">
            <a-input v-model="form.realname" />
          </a-form-model-item>

          <a-form-model-item label="联系电话" prop="phone">
            <a-input v-model="form.phone" disabled />
          </a-form-model-item>

          <a-form-model-item label="邮箱" prop="email">
            <a-input v-model="form.email" disabled />
          </a-form-model-item>
        </div>
        <div class="right">
          <a-form-model-item prop="avatar">
            <AvatarEdit v-model="form.avatar"></AvatarEdit>
          </a-form-model-item>
        </div>
      </div>

      <a-form-model-item :wrapperCol="{span: 24}">
        <div class="btnBroup">
          <a-button type="primary" :loading="loading" class="btnItem" @click="handleSubmit">更新信息</a-button>
        </div>
      </a-form-model-item>
    </a-form-model>
  </div>
</template>
<script>
import './index.less'
import AvatarEdit from './components/AvatarEdit'
import { realNameRuleFunc, phoneRule, emailRule } from '@/utils/rules'
import avatar from '@/assets/avatar.png'
import { getInfo, editUserInfo } from '@/api/currentuser'

// 用户类
class User {
  constructor() {
    this.__init()
  }

  __init() {
    this.username = ''
    this.realname = ''
    this.phone = ''
    this.email = ''
    this.avatar = avatar
  }
}

export default {
  name: 'FormUserBaseInfo',
  components: {
    AvatarEdit
  },
  data() {
    return {
      loading: false,
      form: new User(),
      rules: {
        realname: realNameRuleFunc('用户名称'),
        phone: phoneRule,
        email: emailRule,
        avatar: [{ required: true, message: '请上传头像', trigger: 'blur' }]
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    async fetchData() {
      const data = await getInfo()
      this.form = { ...data }
    },
    async handleSubmit() {
      try {
        this.loading = true
        await this.$refs.form.validate()
        await editUserInfo(this.form)
        this.$message.success('修改用户信息成功!')
        this.$store.dispatch('UserCenterModule/getUserInfo')
      } catch (error) {
        console.log(error)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
