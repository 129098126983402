export default {
  hideEmailInfo: email => {
    if (String(email).indexOf('@') > 0) {
      var newEmail
      var str = email.split('@')
      var _s = ''

      if (str[0].length > 4) {
        _s = str[0].substr(0, 4)
        for (var i = 0; i < str[0].length - 4; i++) {
          _s += '*'
        }
      } else {
        _s = str[0].substr(0, 1)
        for (var j = 0; j < str[0].length - 1; j++) {
          _s += '*'
        }
      }
      newEmail = _s + '***@' + str[1]
      return newEmail
    } else {
      return email
    }
  },
  hideMobileInfo: mobile => {
    var newMobile = ''
    if (mobile.length > 7) {
      newMobile = mobile.substr(0, 3) + '****' + mobile.substr(7)
      return newMobile
    } else {
      return mobile
    }
  }
}
