import './index.less'
import { Divider, Form, Button, Input } from 'ant-design-vue'
import hide from '@/utils/hide'
export default {
  name: 'FormResetEmail',
  props: Object.assign({
    formData: {}
  }),
  created() {
    // 实例创建完成后被立即调用
    console.info('form created')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      console.info('form mounted')
    })
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'FormResetEmail' }),
      timer: '',
      waitSeconds: 0,
      step: 0
    }
  },
  methods: {
    initState() {
      this.waitSeconds = 0
      this.step = 0
      if (this.timer) {
        clearInterval(this.timer)
      }
    },
    // 计时器
    resetWaitSeconds() {
      if (this.waitSeconds > 0) {
        this.waitSeconds = this.waitSeconds - 1
      }
    },
    handleCancel() {
      // 取消提交事件发出
      this.$emit('cancel')
      // 重置form内容
      this.form.resetFields()
      this.initState()
    },
    handleSubmit(e) {
      if (this.step === 1) {
        // 表单提交事件发出
        e.preventDefault()
        this.form.validateFields((err, values) => {
          if (!err) {
            this.$emit('submit', values, this)
          }
        })
      } else if (this.step === 0) {
        this.handelNextSetp()
      }
    },
    setFormError(form, res) {
      var keys = Object.keys(res.data)
      var k = {}
      keys.forEach((item) => {
        k[item] = {
          value: form.getFieldValue(item),
          errors: [new Error(res.data[item])]
        }
      })
      form.setFields(k)
    },
    async handelGetCode() {
      this.form.validateFields(['email'], async (err, values) => {
        if (!err) {
          var res = await this.$store.dispatch('UserCenterModule/sendEmailCodeByCurrentUser')
          if (!res.code) {
            this.waitSeconds = 60
            this.timer = setInterval(this.resetWaitSeconds, 1000)
          }
        }
      })
    },
    async handelNextSetp() {
      if (this.step === 0) {
        this.form.validateFields(['code'], async (err, values) => {
          if (!err) {
            var res = await this.$store.dispatch('UserCenterModule/verifyEmailCodeByCurrentUser', this.form.getFieldValue('code'))
            if (!res.code) {
              this.step = 1
              this.waitSeconds = 0
              this.form.resetFields()
              if (this.timer) {
                clearInterval(this.timer)
              }
            } else if (res.code === 999) {
              this.setFormError(this.form, res)
            }
          }
        })
      } else if (this.step === 1) {
        this.form.validateFields(['phone'], async (err, values) => {
          if (!err) {
            console.info('values', values)
            var res = await this.$store.dispatch('GetValidCodeByEmail', values)
            if (!res.code) {
              this.waitSeconds = 60
              this.timer = setInterval(this.resetWaitSeconds, 1000)
            }
          }
        })
      }
    }
  },
  beforeDestroy() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  },
  render() {
    const { formData = {} } = this.$props
    const { getFieldDecorator } = this.form
    const formItemLayout = {
      labelCol: {
        xs: { span: 22 },
        sm: { span: 6 }
      },
      wrapperCol: {
        xs: { span: 22 },
        sm: { span: 16 }
      }
    }
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0
        },
        sm: {
          span: 20,
          offset: 4
        }
      }
    }
    return (
      <div class='FormResetEmail'>
        <h3>
          修改邮箱</h3>
        <Divider style={{ margin: '20px 0 20px' }} />
        <Form form={this.form}
          labelCol={formItemLayout.labelCol}
          wrapperCol={formItemLayout.wrapperCol}
          onSubmit={this.handleSubmit} layout='horizontal'>
          {this.step === 0 ? <Form.Item label="原邮箱" >
            <div>{
              hide.hideEmailInfo(formData.email ? formData.email : '')}</div>
          </Form.Item> : ''}
          {this.step === 1 ? <Form.Item label="新邮箱" >
            {getFieldDecorator('email', {
              initialValue: '',
              ...this.$validator.email
            })(<Input placeholder='请输入新邮箱' maxLength={20} />)}
          </Form.Item> : ''}
          <Form.Item label="验证码" >
            <div class='codeBox'>
              <div class='code'>
                {getFieldDecorator('code', {
                  initialValue: '',
                  rules: [{
                    required: true,
                    message: '请输入验证码'
                  }]
                })(<Input placeholder='请输入验证码' maxLength={20} />)}
              </div>
              <div class='codeBtn'>
                <Button
                  disabled={this.waitSeconds > 0}
                  type='primary'
                  onClick={this.handelGetCode.bind(this)}>
                  {this.waitSeconds <= 0 ? '获取验证码' : `${this.waitSeconds}秒后重新获取`}</Button>
              </div>
            </div>
          </Form.Item>
          <Form.Item
            labelCol={tailFormItemLayout.labelCol}
            wrapperCol={tailFormItemLayout.wrapperCol} >
            <div class='btnBroup'>
              <Button type="default" onClick={this.handleCancel}>
                取消
              </Button>
              {this.step === 0 ? <Button type="primary"
                onClick={this.handelNextSetp.bind(this)}
                class='btnItem'>
                下一步
              </Button> : ''}
              {this.step === 1 ? <Button type="primary" htmlType="submit" class='btnItem'>
                确定
              </Button> : ''}
            </div>
          </Form.Item>
        </Form>
      </div >
    )
  }
}
