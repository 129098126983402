import './index.less'
import { PageHeaderWrapper } from '@ant-design-vue/pro-layout/es/components'
import { Menu, Icon } from 'ant-design-vue'
import FormUserBaseInfo from './components/FormUserBaseInfo/index.vue'
import FormSecuritySetting from './components/FormSecuritySetting'
export default {
  name: 'UserCenter',
  created() {
    // 实例创建完成后被立即调用
    this.$loadStoreToData(this, 'UserCenterModule')
  },
  mounted() {
    // 实例被挂载后调用
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
      this.loadData()
    })
  },
  data() {
    return {
      menus: [],
      openKeys: [],
      menuSelectKey: []
    }
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('UserCenterModule/getUserInfo')
      this.menus = this.renderMenu([
        { name: '基本信息', id: 'baseInfo' },
        { name: '安全设置', id: 'securitySetting' }
      ])
      if (!(this.menuSelectKey && this.menuSelectKey.length > 0)) {
        this.menuSelectKey = ['baseInfo']
      }
    },
    async handleForm(todo, formData) {
      if (todo === 'baseInfoSubmit') {
        await this.$store.dispatch('UserCenterModule/updateUserBaseInfo', { ...formData })
        await this.$store.dispatch('UserCenterModule/getUserInfo')
      } else if (todo === 'email_submit') {
      } else if (todo === 'username_submit') {
      }
      this.modalShow = false
    },
    renderMenu(data) {
      return data.map((item) => {
        return (item.childVms && item.childVms.length > 0) ? this.renderSubMenu(item) : this.renderMenuItem(item)
      })
    },
    renderSubMenu(item) {
      this.openKeys.push(item.id)
      return <Menu.SubMenu title={
        <div><Icon type='folder' />{item.name}</div>} key={item.id}>
        {this.renderMenu(item.childVms)}
      </Menu.SubMenu>
    },
    renderMenuItem(item) {
      return (
        <Menu.Item key={item.id}>
          <div class='item'>
            <div>{item.name}</div>
          </div>
        </Menu.Item>
      )
    },
    menuItemSelect(item) {
      this.menuSelectKey = item.selectedKeys
    }
  },

  render() {
    return (
      <PageHeaderWrapper>
        <div class='PersonalCenter'>
          <div class="menu">
            <Menu
              selectedKeys={this.menuSelectKey}
              mode={'inline'}
              openKeys={this.openKeys}
              onSelect={this.menuItemSelect.bind(this)}
            >
              {this.menus}
            </Menu>
          </div>
          <div class="content">
            {this.menuSelectKey[0] === 'baseInfo' ? <FormUserBaseInfo
              formData={this.userInfo} onSubmit={this.handleForm.bind(this, 'baseInfoSubmit')} /> : <FormSecuritySetting
              formData={this.userInfo} />}
          </div>
        </div>
      </PageHeaderWrapper >
    )
  }
}
