var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user_info_wrapper FormUserBaseInfo" },
    [
      _c("h2", [_vm._v("基本设置")]),
      _c(
        "a-form-model",
        {
          ref: "form",
          staticClass: "user_info_form",
          attrs: { model: _vm.form, rules: _vm.rules },
        },
        [
          _c("div", { staticClass: "formBody" }, [
            _c(
              "div",
              { staticClass: "left" },
              [
                _c(
                  "a-form-model-item",
                  { attrs: { label: "登录账号", required: "" } },
                  [
                    _c("a-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.username,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "username", $$v)
                        },
                        expression: "form.username",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "用户名称", prop: "realname" } },
                  [
                    _c("a-input", {
                      model: {
                        value: _vm.form.realname,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "realname", $$v)
                        },
                        expression: "form.realname",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "联系电话", prop: "phone" } },
                  [
                    _c("a-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.phone,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "phone", $$v)
                        },
                        expression: "form.phone",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "a-form-model-item",
                  { attrs: { label: "邮箱", prop: "email" } },
                  [
                    _c("a-input", {
                      attrs: { disabled: "" },
                      model: {
                        value: _vm.form.email,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "email", $$v)
                        },
                        expression: "form.email",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "right" },
              [
                _c(
                  "a-form-model-item",
                  { attrs: { prop: "avatar" } },
                  [
                    _c("AvatarEdit", {
                      model: {
                        value: _vm.form.avatar,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "avatar", $$v)
                        },
                        expression: "form.avatar",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
          _c("a-form-model-item", { attrs: { wrapperCol: { span: 24 } } }, [
            _c(
              "div",
              { staticClass: "btnBroup" },
              [
                _c(
                  "a-button",
                  {
                    staticClass: "btnItem",
                    attrs: { type: "primary", loading: _vm.loading },
                    on: { click: _vm.handleSubmit },
                  },
                  [_vm._v("更新信息")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }