import './index.less'
import { Divider, Modal } from 'ant-design-vue'
import FormResetPassword from './components/FormResetPassword'
import FormSetPhone from './components/FormSetPhone'
import FormResetPhone from './components/FormResetPhone'
import FormSetEmail from './components/FormSetEmail'
import FormResetEmail from './components/FormResetEmail'
// import hide from '@/utils/hide'
export default {
  name: 'FormSecuritySetting',
  created() {
    // 实例创建完成后被立即调用
  },
  mounted() {
    this.$nextTick(() => {
      // 整个视图都渲染完毕后执行
    })
  },
  props: Object.assign({
    formData: {}
  }),
  data() {
    return {
      modalBody: null,
      modalShow: false
    }
  },
  methods: {
    async handleForm(todo, formData, form) {
      var res = {}
      switch (todo) {
        case 'resetPwdSubmit':
          res = await this.$store.dispatch('UserCenterModule/changePassword', { ...formData })
          if (!res.code) {
            this.$message.success('密码修改成功！')
            this.modalShow = false
            form.resetFields()
            await this.$store.dispatch('UserCenterModule/getUserInfo')
          } else if (res.code === 999) {
            this.setFormError(form, res)
          }
          break
        case 'resetPwdCancel':
          this.modalShow = false
          break
        case 'setPhoneSubmit':
          res = await this.$store.dispatch(
            'UserCenterModule/bindNewPhone',
            { key: formData.phone, code: formData.code }
          )
          if (!res.code) {
            this.$message.success('手机号设置成功！')
            this.modalShow = false
            form.resetFields()
            await this.$store.dispatch('UserCenterModule/getUserInfo')
          } else if (res.code === 999) {
            this.setFormError(form, res)
          }
          break
        case 'setPhoneCancel':
          this.modalShow = false
          break
        case 'resetPhoneSubmit':
          res = await this.$store.dispatch(
            'UserCenterModule/bindNewPhone',
            { key: formData.phone, code: formData.code }
          )
          if (!res.code) {
            this.$message.success('手机号设置成功！')
            this.modalShow = false
            form.form.resetFields()
            form.initState()
            await this.$store.dispatch('UserCenterModule/getUserInfo')
          } else if (res.code === 999) {
            console.info('resnewPasswordnewPasswordnewPassword', res)
            this.setFormError(form.form, res)
          }
          break
        case 'resetPhoneCancel':
          this.modalShow = false
          break
        case 'setEmailSubmit':
          res = await this.$store.dispatch(
            'UserCenterModule/bindNewEmail',
            { key: formData.email, code: formData.code }
          )
          if (!res.code) {
            this.$message.success('邮箱设置成功！')
            this.modalShow = false
            form.resetFields()
            await this.$store.dispatch('UserCenterModule/getUserInfo')
          } else if (res.code === 999) {
            this.setFormError(form, res)
          }
          break
        case 'setEmailCancel':
          this.modalShow = false
          break
        case 'resetEmailSubmit':
          res = await this.$store.dispatch(
            'UserCenterModule/bindNewEmail',
            { key: formData.email, code: formData.code }
          )
          if (!res.code) {
            this.$message.success('邮箱设置成功！')
            this.modalShow = false
            form.form.resetFields()
            form.initState()
            await this.$store.dispatch('UserCenterModule/getUserInfo')
          } else if (res.code === 999) {
            this.setFormError(form.form, res)
          }
          break
        case 'resetEmailCancel':
          this.modalShow = false
          break
        default:
          break
      }
    },
    setFormError(form, res) {
      var keys = Object.keys(res.data)
      var k = {}
      keys.forEach((item) => {
        k[item] = {
          value: form.getFieldValue(item),
          errors: [new Error(res.data[item])]
        }
      })
      form.setFields(k)
    },
    handleListItemBtnClick(todo) {
      switch (todo) {
        case 'resetPwd':
          this.modalBody = <FormResetPassword
            onCloseModal={() => { this.modalShow = false }}
            onCancel={() => { this.modalShow = false }}
          />
          break
        case 'newPhone':
          this.modalBody = <FormSetPhone
            onSubmit={this.handleForm.bind(this, 'setPhoneSubmit')}
            onCancel={this.handleForm.bind(this, 'setPhoneCancel')} />
          break
        case 'editPhone':
          this.modalBody = <FormResetPhone
            formData={this.formData}
            onSubmit={this.handleForm.bind(this, 'resetPhoneSubmit')}
            onCancel={this.handleForm.bind(this, 'resetPhoneCancel')} />
          break
        case 'newEmail':
          this.modalBody = <FormSetEmail
            formData={this.formData}
            onSubmit={this.handleForm.bind(this, 'setEmailSubmit')}
            onCancel={this.handleForm.bind(this, 'setEmailCancel')} />
          break
        case 'editEmail':
          this.modalBody = <FormResetEmail
            formData={this.formData}
            onSubmit={this.handleForm.bind(this, 'resetEmailSubmit')}
            onCancel={this.handleForm.bind(this, 'resetEmailCancel')} />
          break
        default:
          break
      }
      this.modalShow = true
    }
  },
  render() {
    const { title } = this.$props
    return (
      <div class='FormSecuritySetting'>
        <Modal maskClosable={false} closable={false} footer={null} visible={this.modalShow}>
          {this.modalBody ? this.modalBody : ''}
        </Modal>
        <h2>
          {title}安全设置</h2>
        <div class='fslist'>
          <div>
            <div class='fslistItem'>
              <div class='itemBody'>
                <div class='bodyTitle'>密码</div>
                <div class='subTitle'>******</div>
              </div>
              <div class='itemExt'>
                <a onClick={this.handleListItemBtnClick.bind(this, 'resetPwd')}>修改</a>
              </div>
            </div>
            <Divider />
          </div>
          {/* <div>
            <div class='fslistItem'>
              <div class='itemBody'>
                <div class='bodyTitle'>手机号（可用于找回密码）</div>
                <div class='subTitle'>{this.formData.phone ? hide.hideMobileInfo(this.formData.phone) : '未设置'}</div>
              </div>
              <div class='itemExt'>
                <a
                  onClick={this.handleListItemBtnClick.bind(this, this.formData.phone ? 'editPhone' : 'newPhone')}
                >{this.formData.phone ? '修改' : '设置'}</a>
              </div>
            </div>
            <Divider />
          </div> */}
          {/* <div>
            <div class='fslistItem'>
              <div class='itemBody'>
                <div class='bodyTitle'>邮箱（可用于找回密码）</div>
                <div class='subTitle'>{this.formData.email ? hide.hideEmailInfo(this.formData.email) : '未设置'}</div>
              </div>
              <div class='itemExt'>
                <a
                  onClick={this.handleListItemBtnClick.bind(this, this.formData.email ? 'editEmail' : 'newEmail')}
                >{this.formData.email ? '修改' : '设置'}</a>
              </div>
            </div>
            <Divider />
          </div> */}
        </div>
      </div>
    )
  }
}
